/**
 *  @description 规则小驼峰（webGet + Admin + 文件名）。接口信息定义: 以当前接口请求头作为别名,并将相关接口放置在该文件下
 *  @date 1949-10-01
 */
import service from "@/http/httpService";

// 获取地域列表
export const webUserGetCountryList = (params) => service.post("/api/country/list", { data: params, needEn: true });
// 注册
export const webUserRegister = (params) => service.post("/api/app/user/userRegister", { data: params, needEn: true });
// 登录
export const webUserLogin = (params) => service.post("/api/app/user/login", { data: params, needEn: true });
// APP 忘记密码
export const webGetUserForgotPassword = (params) => service.post("/api/app/user/forgotPassword", { data: params, needEn: true });
// 获取用户信息
export const webUserGetUserInfo = (params) => service.post("/api/app/user/userInfo", { data: params, needEn: true });
// App重置二级密码
export const webUserResetSecurityPassword = (params) => service.post("/api/app/user/updateTransactionPassword", { data: params, needEn: true });
// App重置密码
export const webUserResetPassword = (params) => service.post("/api/app/user/updatePassWord", { data: params, needEn: true });
// // App Kyc
// export const webUserToKyc = (params) => service.post("/api/user-authentication-log/authentication", { data: params, needEn: true });
// // App 实名认证信息
// export const webUserQueryKycInfo = (params) => service.post("/api/user-authentication-log/lastAuthentication", { data: params, needEn: true });
// App 忘记交易密码
export const webUserForgotSecurityPwd = (params) => service.post("/api/app/user/forgotTransactionPassword", { data: params, needEn: true });

// 检查二级密码
export const webGetUserCheckSecurityPassword = (params) => service.post("/api/app/wu-user/checkSecondaryPassword", { data: params, needEn: true });
// 设置二级密码
export const webGetUserSetSecurityPassword = (params) => service.post("/api/app/wu-user/addSecondaryPassword", { data: params, needEn: true });

// 获取等级
export const webUserQueryLevelList = (params) => service.post("/api/level-config/appGetLevelConfig", { data: params, needEn: true });

// App查询币种列表
export const webUserGetCoinList = (params) => service.post("/api/app/wu-coin/appCoinList", { data: params, needEn: true });

// App钱包列表
export const webUserGetAssetsList = (params) => service.post("/api/app/assets/assetsList", { data: params, needEn: true });
// App资产记录
export const webUserGetAssetsRecords = (params) => service.post("/api/app/assets/assetsRecordList", { data: params, needEn: true });
// App资产记录
export const webUserGetAssetsRatioRecords = (params) => service.post("/api/app/assets/transactionRatioList", { data: params, needEn: true });
// App查询资产类型列表
export const webUserGetRecordType = (params) => service.post("/api/app/assetsRecord/type/subList", { data: params, needEn: true });

// App 钱包转换
export const webUserToConvert = (params) => service.post("/api/app/convert/convert", { data: params, needEn: true });
// App 兑换配置
export const webUserGetConvertConfig = (params) => service.post("/api/app/convert/convertConfigList", { data: params, needEn: true });
// App 兑换配置
export const webUserGetConvertRecord = (params) => service.post("/api/app/convert/convertOrderList", { data: params, needEn: true });
// App 取消兑换
export const webUserCancelConvert = (params) => service.post("/api/app/convert/cancelConvert", { data: params, needEn: true });
// // App 兑换记录
// export const webUserGetConvertRecord = (params) => service.post("/api/app/wu-convert/appConvertOrderList", { data: params, needEn: true });
// App 合约钱包锁仓时间
export const webUserGetConvertTime = (params) => service.post("/api/app/assets/assetsContractAccount", { data: params, needEn: true });

// App 转让
export const webUserToMakeOver = (params) => service.post("/api/app/wu-make-over/makeOver", { data: params, needEn: true });
// App 转让配置
export const webUserGetMakeOverConfig = (params) => service.post("/api/app/wu-make-over/appMakeOverConfigList", { data: params, needEn: true });
// App 转让记录
export const webUserGetMakeOverRecord = (params) => service.post("/api/app/wu-make-over/makeOverList", { data: params, needEn: true });

// App 提币
export const webUserToWithdrawal = (params) => service.post("/api/app/withdrawal/withdrawal", { data: params, needEn: true });
// App 提币配置
export const webUserGetWithdrawalConfig = (params) => service.post("/api/app/withdrawal/configList", { data: params, needEn: true });
// App 提币记录
export const webUserGetWithdrawalRecord = (params) => service.post("/api/app/withdrawal/withdrawalOrderList", { data: params, needEn: true });

// 获取首页轮播图
export const webUserGetBannerList = (params) => service.post("/api/app/wu-frontPage/carousel", { data: params, needEn: true });
// 获取分享二维码
export const webUserGetShareCode = (params) => service.post("/api/app/wu-user/invitation", { data: params, needEn: true });

// APP 币种类型
export const webUserGetMainCoinTypeList = (params) => service.post("/api/app/wu-deposit/appFindCoinTypeList", { data: params, needEn: true });

// 充值
export const webUserRecharge = (params) => service.post("/api/app/deposit/deposit", { data: params, needEn: true });
// 充值记录
export const webUserRechargeRecords = () => service.post("/api/app/wu-assets/assetsDepositRecordList", { data: {}, needEn: true });

// App查询邀请用户
export const webUserQueryInvitationUserList = (params) => service.post("/api/app/user/organization", { data: params, needEn: true });
// App查询收益
export const webUserQueryIncome = (params) => service.post("/api/app/user/income", { data: params, needEn: true });

// App查询配套配置
export const webUserQueryMatchingList = (params) => service.post("/api/app/wu-matching/appMatchingList", { data: params, needEn: true });
// // App查询当前配套
// export const webUserQueryCurrentMatching = params => service.post('/api/app/wu-matching/appQueryCurrentMatching', {data: params, needEn: true});
// App购买配套
export const webUserBuyMatching = (params) => service.post("/api/app/wu-matching/buyMatching", { data: params, needEn: true });
// App查询配套收益
export const webUserQueryMatchingProfit = (params) => service.post("/api/app/wu-matching/appBuyMatchingProfit", { data: params, needEn: true });
// App查询购买配套
export const webUserQueryMatchingRecords = (params) => service.post("/api/app/wu-matching/appBuyMatchingList", { data: params, needEn: true });

// 购买配套点击
export const webGetToClickMatching = (params) => service.post("/api/user-click-matching-log/clickMatching", { data: params, needEn: true });
// 配套点击记录
export const webGetClickMatchingRecords = (params) => service.post("/api/user-click-matching-log/clickMatchingLog", { data: params, needEn: true });

// 查询通知
export const webGetQueryNoticeList = (params) => service.post("/api/app/wu-notice/appNoticeList", { data: params, needEn: true });
// 查询媒体
export const webGetQueryMediaList = (params) => service.post("/api/app/wu-media/appMediaList", { data: params, needEn: true });

// 绑定钱包地址
export const webGetBindAddress = (params) => service.post("/api/app/wu-user/appUpdateWalletAddress", { data: params, needEn: true });

// 绑定谷歌验证
export const webGetBindGoogleAuth = (params) => service.post("/api/google-auth/bindGoogle", { data: params, needEn: true });
// 生成谷歌验证
export const webGetGenerateGoogleAuth = (params) => service.post("/api/google-auth/generateGoogleSecret", { data: params, needEn: true });
// 查询谷歌验证
export const webGetQueryGoogleAuth = (params) => service.post("/api/google-auth/getGoogleAuthByUser", { data: params, needEn: true });
// 终止配套
export const webCancelClickMatching = (params) => service.post("/api/user-click-matching-log/cancelClickMatching", { data: params, needEn: true });
// 配套点击收益明细
export const webAppMatchingClickIncome = (params) => service.post("/api/app/wu-assets/appMatchingClickIncome", { data: params, needEn: true });
// 充值列表
export const webAppDepositByMatching = (params) => service.post("/api/app/wu-deposit/appDepositByMatching", { data: params, needEn: true });
// 发送邮件
export const webSendEmailCode = (params) => service.post("/api/app/wu-user/sendEmailCode", { data: params, needEn: true });

// App领取奖励
export const webReceiveReward = (params) => service.post("/api/app/assets/receiveAssetsReward", { data: params, needEn: true });
// App 修改自动复投开关
export const updateContractStatus = (params) => service.post("/api/app/user/autoContractStatus", { data: params, needEn: true });

// App 获取return
export const getReturnWallet = (params) => service.post("/api/app/assets/returnAssets", { data: params, needEn: true });

// App查询配套
export const getMatchingList = (params) => service.post("/api/app/matching/matchingList", { data: params, needEn: true });
// App投资资产数量集合
export const getInvestListAmount = (params) => service.post("/api/app/assets/investAmountList", { data: params, needEn: true });


// App查询轮播图列表
export const getBannerList = (params) => service.post("/api/app/notice/appRotationNoticeList", { data: params, needEn: true });
// App查询公告列表
export const getNoticeList = (params) => service.post("/api/app/notice/appNoticeList", { data: params, needEn: true });


// App查询组织
export const webUserGetInviteTree = (params) => service.post("/api/app/user/organization", { data: params, needEn: true });

// App获取邀请链接
export const webUserGetInviteLink = (params) => service.post("/api/app/user/invitationLink", { data: params, needEn: true });

// 登录状态发送验证码
export const webUserGetCodeOnLogin = (params) => service.post("/api/app/user/loginSend", { data: params, needEn: true });
// 非登录状态发送验证码
export const webUserGetCodeOnNoLogin = (params) => service.post("/api/app/user/noLoginSend", { data: params, needEn: true });


// 获取撤资记录
export const webUserGetDevestRecords = (params) => service.post("api/app/assets/divestmentRecord", { data: params, needEn: true });

// 获取服务器时间
export const webUserGetServeTime = (params) => service.post("/api/app/user/serverTime", { data: params, needEn: true });

// App查询最小投资数量信息
export const webUserGetMinInvestInfo = (params) => service.post("/api/app/assets/minInvestInfo", { data: params, needEn: true });

// App钱包转换
export const webUserToInvest = (params) => service.post("/api/app/assets/assetsChange", { data: params, needEn: true });

// App检测组织图
export const webUserCheckInviteTree = (params) => service.post("/api/app/user/checkOrganization", { data: params, needEn: true });

// App查询交易记录
export const webUserGetTransactionList = (params) => service.post("/api/app/arbitrage/arbitrageTransactionList", { data: params, needEn: true });

// App查询社区信息
export const webUserGetCommunityInfo = (params) => service.post("/api/app/user/communityInfo", { data: params, needEn: true });

// 代金券提现
export const voucherWithdraw = (params) => service.post("/api/app/withdrawal/withdrawalVoucherReward", { data: params, needEn: true });

// 修改自动复投状态
export const changeReturnInviteStatus = (params) => service.post("api/app/user/updateReturnInvestStatus", { data: params, needEn: true });
